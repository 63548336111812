import * as React from 'react'

import Layout from 'layouts/main'
import HeroSection from 'components/program/heroSection'
import ProgramSection from 'components/program/programSection'
import SelectionSection from 'components/program/selectionSection'
// import SupportedSection from 'components/program/supportedSection'
import BusinessSection from 'components/program/businessSection'
import CoursesSection from 'components/home/coursesSection'
import FaqSection from '../components/program/faqSection'
import LetsTalk from 'components/program/letsTalk'
import GameChangerSection from '../components/program/gameChangerSection'
import ValueTimeCommitment from '../components/program/valueTimeCommitment'
import WallOfLove from '../components/program/wallOfLove'
// import FaqSection from '../components/program/faqSection'

const ProgramPage = ({ pageContext }) => {
  const { programs, faqs } = pageContext

  return (
    <div className="Homepage program">
      <Layout title="Program">
        <HeroSection />
        <GameChangerSection />
        <LetsTalk />
        {/* <ProgramSection /> */}
        <ValueTimeCommitment />
        <WallOfLove />
        <SelectionSection />
        <CoursesSection programs={programs} />
        <BusinessSection />
        {faqs.length > 0 && <FaqSection questions={faqs} />}
      </Layout>
    </div>
  )
}

export default ProgramPage

import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;

  > div:first-child {
    align-self: flex-start;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    h2 {
      font-size: clamp(1.2rem, 4vh, 2.5rem);
    }
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 48px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin-top: 16px;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 1 45%;

  p {
    font-weight: 400;
    line-height: 1.8;
  }

  > p:first-child {
    margin-top: 0;
  }

  > button {
    svg {
      margin-left: 12px;
    }
  }
`

const ImageContainer = styled.div`
  flex: 0 1 50%;
  position: relative;
  display: flex;
  justify-content: flex-end;
`

const BusinessSection = () => (
  <Container>
    <FixedContainer>
      <ShadowText position="bottom" highlight>
        <h3>Learn now and pay later</h3>
      </ShadowText>
      <Content>
        <ImageContainer>
          <div>
            <StaticImage
              src="../../images/team.jpg"
              alt="meeting"
              layout="constrained"
              placeholder="tracedSVG"
            />
          </div>
        </ImageContainer>
        <DescriptionContainer>
          <h6>
            {getTranslation(
              'business_model_description',
              'Our model is based on the individuals (CEOs, Founders, Directors,\n' +
                '            etc) working in Innovative Economy in Armenia or outside, willing to\n' +
                '            help the professional development of the students (and hire them\n' +
                '            after graduation). We are going to accept trustees who will donate\n' +
                '            recurring payments (min $200/mo) and vote for the best products at\n' +
                '            the end of each batch.',
            )}
          </h6>
        </DescriptionContainer>
      </Content>
    </FixedContainer>
  </Container>
)

export default BusinessSection

// import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
// import { SecondaryButtonLink } from 'components/styled/button'

import ShoutoutEmbed from 'shoutout-react'
import { Card } from 'components/styled/card'
import { ShadowText } from 'components/styled/typography'
// import Twitter from 'svgs/twitter.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  } 
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1480px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Row = styled.div`
  padding: 30px 30px 0 30px;
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
`

const WallOfLove = () => {
  return (
    <div>
      <div className="page" id='wall'>
        <Container className="container">
          <FixedContainer>
            <ShadowText highlight>
              <h3>The wall of love</h3>
            </ShadowText>
            <Row>
              <ShoutoutEmbed url="Buildup" />
            </Row>
          </FixedContainer>
        </Container>
      </div>
    </div>
  )
}

export default WallOfLove
